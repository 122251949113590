import React from "react"
import Layout from "../components/layout"

export default ({ data, pageContext }) => {
	return (
		<Layout> 
			<div className="row">
				<div className="col d-flex justify-content-center">
					<div className="post--inner"
						 style={{
							borderColor:  "rgba(247,227,227, 0.5)",
							borderWidth: "3px",
							width: "720px"
						 }}>
						<p>
							Hi! Welcome to my internet area. I’m Jacob Hodes.							
							For the past five years I’ve been building <a href="https://www.relevant.healthcare">Relevant</a>.
							 Before that I was dabbling in journalism.
						</p>

						<p>
							Here's my <a href="https://twitter.com/peashutop">Twitter</a>, <a href="https://www.instagram.com/peashutop/">Instagram</a>, <a href="https://www.goodreads.com/user/show/202829-jacob">Goodreads</a>, <a href="https://github.com/peashutop/">GitHub</a>, and (odious but useful) <a href="https://www.linkedin.com/in/jacob-hodes-239a1619/">LinkedIn</a>.
							If you’d like to get in touch, the best way is email: <a href="mailto:jacob@peashutop.com">jacob@peashutop.com</a>.
						</p>
						
						<p>
							N.b. I’m not a lawyer or equities trader; that's the other Jacob Hodes, who currently 
							works at Brown Advisory, and whom you can learn more about <a href="https://www.brownadvisory.com/jacob-hodes">here</a>. <em>Update May 2020</em>: We found another one! Meet the young Australian 
							upstart Jacob Hodes <a href="https://www.linkedin.com/in/jacobhodes/">here</a>.
						</p>
					</div>
				</div>
			</div>
		</Layout>
	)
}
